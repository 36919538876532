import React from 'react'
import phoneimg from '../assets/images/iphone1.png';

export default function Column4() {
    return (
        <div className='container mx-auto md:mt-8 md:py-5'>
            <div className="md:flex mb-8 items-center">
                <div className="w-full md:w-1/2 flex flex-col justify-center md:justify-start">
                    <div className='flex flex-col mx-auto'>
                    <h1 className="text-3xl md:text-4xl font-bold mb-2 text-gray-900 text-center md:text-left">Earn With Us</h1>

                        <p className="text-sm md:text-base mb-4 text-gray-900 text-center md:text-left">
                            Katswiri empowers you to connect with customers <br />
                            across the nation, delivering impactful experiences <br />
                            at scale. From showcasing your products and <br />
                            services to boosting sales and cultivating strong <br />
                            relationships, Katswiri helps you achieve your <br />
                            business goals efficiently and effectively.
                        </p>
                    </div>
                    <div className='flex flex-col md:flex-row gap-3 text-center justify-center mx-auto'>
                        <button className="bg-custom-color hover:bg-gray-900 text-white py-2 px-4 rounded-full">Become a Katswiri</button>
                        <button className="bg-transparent text-blue-800 hover:bg-gray-900 py-2 px-4 rounded-full border border-gray-800">How Does It Work?</button>
                    </div>
                </div>

                <div className="w-full md:w-[50%] md:w-1/2 pt-5">
                    <img src={phoneimg} alt="Image" className="w-[65%] md:w-[60%] h-auto mx-auto md:mx-0" />
                </div>




            </div>
        </div>
    )
}
