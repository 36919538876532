import React from 'react'
import NavBar2 from '../components/Nav2';
import Heading from '../components/Heading';
import Description from '../components/Description';
import Steps from '../components/Steps';
import Footer2 from '../components/Footer2';
import OtherNav from '../components/other_nav';

function HowItWorks() {
    return (
    <div>
      <div className='container'>
        {/* <NavBar2 /> */}
        <OtherNav />
        </div>

        <div>
        <Heading />
        </div>

        <Description />

        <Steps />

        <Footer2 />
    </div>
  )
}

export default HowItWorks;
