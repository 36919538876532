import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FaBars } from 'react-icons/fa';
import { IoMdClose } from "react-icons/io";
import { IoIosArrowForward } from "react-icons/io";
import logo from '../assets/images/katswiri.png'

const Navbar3 = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [activeTab, setActiveTab] = useState("");
    const [scrolling, setScrolling] = useState(false);


    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 0) {
                setScrolling(true);
            } else {
                setScrolling(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);


    return (
        <nav className={`w-[100%] fixed top-0 left-0 md:px-8 py-2 bg-gray-200 bg-opacity-15 ${scrolling ? 'md:bg-custom-color md:bg-opacity-50' : 'md:bg-transparent'} bg-opacity-95 ${isOpen ? 'rounded-xl' : ''} mx-auto md:my-4 font-inter`}>
            <div className="flex justify-between w-full items-center h-16">
                {/* Logo Section */}
                <div>
                    <a href='#'>
                        <div className='w-40 h-auto'>
                            <img src={logo} alt="Logo" className="w-full h-full" />
                        </div>
                    </a>
                </div>

                {/* Menu Button (Mobile) */}
                <button onClick={toggleMenu} className="ml-auto sm:hidden text-black">
                    {isOpen ? (
                        <IoMdClose size={24} />
                    ) : (
                        <FaBars size={24} />
                    )}
                </button>

                {/* Nav Links (Larger Screens) */}
                <div>
                    <ul className={`hidden sm:flex space-x-6 ${scrolling ? 'text-white' : 'text-black'} font-inter font-light`}>
                        <li>
                            <a href="#services" onClick={() => setActiveTab("features")}>
                                <div className={`rounded-full px-2 py-1 ${activeTab === "features" ? "font-bold" : "bg-none"}`}>
                                    Services
                                </div>
                            </a>
                        </li>
                        <li>
                            <a href="how-it-works" onClick={() => setActiveTab("faq")}>
                                <div className={`rounded-full px-2 py-1 ${activeTab === "faq" ? "font-bold" : "bg-none"}`}>
                                    How Katswiri Works
                                </div>
                            </a>
                        </li>
                    </ul>
                </div>

                {/* Download Button (Larger Screens) */}
                <div className='flex gap-x-4 items-center justify-center'>
                <a href='#download'>
                <div className='hidden sm:flex bg-black rounded-full py-2 px-4 my-3 font-light text-md hover:scale-105 text-white cursor-pointer'>
                    Become Katswiri
                </div>
                </a>
                <a href='#download'>
                <div className={`hidden sm:flex bg-transparent border ${scrolling ? 'border-white' : 'border-black'} rounded-full py-2 px-4 my-3 font-light text-md hover:scale-105 ${scrolling ? 'text-white' : 'text-black'} cursor-pointer`}>
                    Sign In
                </div>
                </a>
                </div>
            </div>

            {/* Mobile nav */}
            {isOpen && (
                <div className="sm:hidden mt-4">
                    <ul className="flex flex-col space-y-4 text-black font-light text-center">
                        <a href="#services">
                            <li className="hover:font-bold flex justify-center">
                                Services
                            </li>
                        </a>
                        <a href="how-it-works">
                            <li className="hover:font-bold flex justify-center">
                                How Katswiri Works
                            </li>
                        </a>
                        <li>
                            <a href="#download">
                                <div className='bg-black text-white rounded-full py-2 px-4 my-3 font-semibold text-md hover:scale-105 w-full mx-auto'>Become Katswiri</div>
                            </a>
                        </li>
                    </ul>
                </div>
            )}
        </nav>
    );
};

export default Navbar3;
