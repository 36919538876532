import React from 'react'

function TermsBody() {
    return (
        <div className='bg-body-color'>
            <div className='px-10'>
                <h1 className='text-white font-bold text-3xl py-2'>KATSWIRI TERMS OF USE</h1>
                <p className='text-white text-md py-2'>Updated on April 26, 2024</p>

                <h1 className='text-white text-2xl font-bold py-2'>1. OVERVIEW</h1>
                <p className='text-white text-md py-2'>
                    1.1 Katswiri is your go-to call-in and online mobile platform for connecting with skilled taskers in your local area. We specialize in helping individuals, families/groups, and organizations find the right professionals for a wide range of tasks. <br />
                    1.2 These terms and conditions apply when you visit or use the Katswiri website (<a href="https://katswiri.com" className='text-blue-500'>katswiri.com</a> ) and mobile application (Android or iOS), as well as other related websites, features, widgets, and other online-controlled services offered by Katswiri. <br />
                    1.3 Please read the Terms of Service carefully before accessing or using our website. You acknowledge and agree to be governed by these Terms of Use by accessing or using our website, mobile app, or other sites and Katswiri services. You acknowledge and agree to comply with and observe these Katswiri Terms of Use on behalf of yourself, the firm, or other organizations (where applicable).
                </p>


                <h1 className='text-white text-2xl font-bold py-2'>2. REGISTRATION AND ACCOUNT CANCELLATION</h1>
                <p className='text-white text-md py-2'>
                    2.1To use or access the Katswiri services, you must have an internet-connected device (Android or iOS) and sign in or have a
                    phone for the call-in services. Katswiri membership will be retained by the user until it is terminated by the user or deactivated by Katswiri administrators. <br />


                    2.2 Registration to Katswiri platforms is free, individuals, entity representatives, and companies who require a subscription
                    must pay according to their subscription plan. The subscription plan is divided into three categories: monthly,
                    three-month, and yearly. An individual charge is different from a home, or company’s rate, if you want to
                    understand more about pricing contact <a href="tel:+265211776931" className='text-blue-500'>265211776931</a> or email <a href="mailto:info@katswiri.com" className='text-blue-500'>info@katswiri.com</a> or <a href="mailto:info@staticcomputersinc.com" className='text-blue-500'>info@staticcomputersinc.com</a> <br />

                    2.3 Taskers can choose commission-based or sub-contract-based services. Those who are subcontracted are paid every month, and in some situations, with interest. <br />

                    2.4 Users agree to provide accurate contact information, identity documents, and correct billing information which may include transaction data. <br />

                    2.5 For call-in services, clients call our Customer Service Team and are charged a fee for the call, to receive free assistance. <br />

                    2.6 Payments. To complete transactions, our Users might provide one or more payment methods. Payments are made to either
                    the Katswiri or the Static Computers Inc accounts.  Users can make direct payments to our accounts for subscription plans. <br />

                    2.7 Following the filing of an invoice, all of our clients' payments are sent to Static Computers Inc at the agreed-upon time.
                    This invoice is calculated using a task price or an hourly work rate. The invoice may include the agreed-upon expenses. <br />


                    2.8 When transactions are made using credit or debit cards, we authenticate you by checking the cardholder's identity.
                    We will confirm your name, address, and other personal information provided throughout the order process
                    against information stored by the card issuer and integrator to ensure that your credit or debit card is not being
                    used without your permission. You consent to such checks by accepting these terms and conditions. <br />

                    2.9 Katswiri reserves the right to adjust its subscription plan costs and service pricing at any time.
                    These changes will be reflected on our official websites, and users will be notified via email. If the user
                    does not agree with the price changes, they may opt to terminate their subscription. Users also agree that Katswiri may
                    delete their accounts if they do not comply with our business practices, breach our policies, or violate the rights of another user. <br />


                    2.10 Katswiri members have the option to terminate or delete their accounts at any moment. You can do this by going to
                    your Katswiri account and following the cancellation instructions or by clicking on deactivate account. However, please
                    keep in mind that payments to Katswiri are non-refundable.
                </p>

                <h1 className='text-white text-2xl font-bold py-2'>3. ALLOWED USE AND LICENCE </h1>
                <p className='text-white text-md py-2'>
                    3.1  Users agree to use Katswiri services, including all related features and their functionalities, unless
                    prohibited by applicable law or other restrictions on the use of our services or material, in which case
                    you agree not to:

                    <ul className='ml-8 list-disc'>
                        <li>Not to upload any defamatory, unlawful, harassing, explicit, offensive, misleading, or infringing privacy rights information or materials.  </li>
                        <li>Do not attempt any type of scam or fraud involving Katswiri and its services. </li>
                        <li>Not to post, upload, email, or send and transmit any content with the intent of disrupting, destroying, or limiting the functionality of Katswiri software and hardware, or any telecommunications equipment associated with Katswiri services.</li>
                        <li>Without explicit permission, you may not use or attempt to use any robot, web spider, web crawler, harvesting bot, or scraper to search for the Katswiri website.</li>
                        <li>Users are encouraged to report any activity or content on our website that they believe violates our terms of reference to us (through <a href="mailto:info@katswiri.com" className='text-blue-500'>info@katswiri.com</a> )</li>
                    </ul>

                </p>

                <h1 className='text-white text-2xl font-bold py-2'>4. ACCOUNT ACCESS AND PASSWORDS</h1>
                <p className='text-white text-md py-2'>
                    4.1 We will create your Katswiri account once you have completed the registration process. To access your Katswiri Account and use
                    the Katswiri Service, you will need to use your email address, cell phone number, password, or other user credentials. <br />

                    4.2 Taskers can create a profile with different services to increase their chances of being picked by clients. <br />

                    4.3 Keep the security and privacy of your Katswiri account, passwords, login credentials, and payment information. We shall
                    treat any usage of the Katswiri services or any other act performed with your password, through your Katswiri Account,
                    or the use of your user credentials as if it were done by yourself and with your approval. <br />

                    4.4 If you suspect that someone has gained unauthorized access to your Katswiri Account, password, user credentials,
                    payment information, or payment details, you must contact us immediately by sending an email to info@katswiri.com <br />

                    4.5 Katswiri shall not be liable for any loss or damage the User may experience if someone else uses their password,
                    login credentials, payment information, or payment details without user knowledge or agreement. As part of our Terms of Service,
                    Katswiri may assist with property damage, or bodily injury sustained while performing Katswiri tasks. <br />

                    4.6 Katswiri is not responsible for any internet connection, service provider, or data usage fees.
                    The user agrees and acknowledges that these fees are settled by them. <br />

                    4.7 You (Users) may choose to update or change/reset your passwords at any time if you believe your privacy has been violated.
                </p>

                <h1 className='text-white text-2xl font-bold py-2'>5. SECURITY</h1>
                <p className='text-white text-md py-2'>
                    5.1 Katswiri is dedicated to providing excellent and secure experiences. We take user information security very seriously,
                    and any sensitive information submitted when posting a task or enrolling as a Tasker is handled carefully by our Privacy Policy. <br />

                    5.2 Katswiri encrypts all data in transit using Hypertext Transfer Protocol Secure (HTTPS) and imposes HTTP Strict Transport Security (HSTS). <br />


                    5.3 To assist you safeguard your account, we provide several security measures like Safe Browsing, Security Checkups, and 2 Step Verification. <br />


                    5.4 We examine our information collection, storage, and processing protocols regularly to prevent unauthorized access to our systems.
                </p>

                <h1 className='text-white text-2xl font-bold py-2'>6. PRIVACY</h1>
                <p className='text-white text-md py-2'>
                    6.1 Our Privacy Statement explains how Katswiri ("Katswiri," "we," or "us") collects, uses, and discloses information about you. <br />

                    6.2 Our Privacy Policy applies to the personal information we collect when you access or use our websites, mobile applications, telephone
                    service, or other platforms or properties (the "Properties"), use our products and services (individually and collectively
                    with the Properties, the "Services"), or interact with us in any other way. This Privacy policy also applies to the personal information we obtain
                    from other sources. Please do not use the Services if you do not agree with the conditions of the Katswiri Privacy Policy. <br />

                    6.3 We reserve the right to modify our Privacy policy at any time. If we make changes, we will notify you by amending the effective date at the
                    top of this Privacy Policy and displaying the revised Privacy Policy on our website. If we make significant changes, we will notify you in advance
                    (for example, by posting a statement on our website or sending you an email). <br />

                    6.4 You can access the complete privacy policy on <a href='https://katswiri.com'>katswiri.com</a>  or request a copy at <a href="mailto:info@katswiri.com" className='text-blue-500'>info@katswiri.com</a> <br />

                    6.5 You (User, Viewer) agree to be governed by the privacy policy as if it were completely included in these terms of service.
                </p>

                <h1 className='text-white text-2xl font-bold py-2'>7. CHANGES TO THIS AGREEMENT AND THE KATSWIRI PLATFORM</h1>
                <p className='text-white text-md py-2'>
                    7.1Katswiri reserves the right, in its sole and absolute discretion, to change, modify, add to, supplement, suspend,
                    discontinue, or delete any of the terms and conditions of this Agreement (including the Terms of Service and Privacy Policy),
                    as well as review, improve, modify, or discontinue, temporarily or permanently, the Katswiri Platform or any content or information
                    available through the Katswiri Platform at any time, with or without prior notice. <br />

                    7.2Katswiri may also, without notice or liability, limit some features or restrict your access to part or all of the Katswiri Platform. <br />

                    7.3Katswiri will make reasonable efforts to notify you of material changes to this Terms of Use by email, or by pop-up notification on our
                    website. If any future changes to this Agreement are undesirable to you or cause you to be in breach of this Agreement, you may delete your
                    account and cease using the Katswiri Platform immediately. <br />

                    7.4Except where prohibited by applicable laws or regulations in your jurisdiction, your continued use of the Katswiri Platform following any revision to this Agreement signifies your complete and binding acceptance of any such changes.
                </p>

                <h1 className='text-white text-2xl font-bold py-2'>8. TERMINATION OF THIS AGREEMENT</h1>
                <p className='text-white text-md py-2'>
                    8.1 We may monitor and/or record your communications with us or with a client or a User via the Services (including when we
                    connect calls between Users and clients) for quality assurance, customer satisfaction, and other purposes.
                    Calls between clients and Users may also be monitored and recorded. By providing us with your phone number,
                    you agree to this monitoring and recording. <br />

                    8.2 Consent to receive automated calls/texts. By giving your phone number(s), you agree to receive auto-dialed, pre-recorded, or artificial
                    voice calls and text (SMS) messages, even if your phone number(s) is on the Do Not Call list. <br />

                    8.3 Your consent to receive automated calls and messages is entirely voluntary. You have the option to opt-out at any time. To opt out of text messaging,
                    send STOP to <a href="mailto:info@katswiri.com" className='text-blue-500'>info@katswiri.com </a> <br />

                    8.4 Any breach of security or unauthorized use of your telephone device must be reported promptly to Katswiri. (info@katswiri.com ) or (info@staticcomputers.com).
                </p>

                <h1 className='text-white text-2xl font-bold py-2'>9. COMMUNICATION</h1>
                <p className='text-white text-md py-2'>
                    9.1 We will communicate with users regarding their accounts (e.g., password changes, payment authorization, confirmation messages, and notices) through pop-up notifications when using our services and via text or email using the user credentials provided during registration.
                    <br /> 9.2 When referring to Kanema Online as "We" or "Us," it includes our service providers, agents, 
                    affiliates, and representatives. However, users should verify the relevance of any details 
                    before making decisions, entering into agreements, or conducting transactions. We shall not be held responsible 
                    for any loss or liabilities. Official communications will be made through 
                    <a href="mailto:info@katswiri.com" className='text-blue-500'>account@kanemaonline.com</a> and 
                    <a href="mailto:info@katswiri.com" className='text-blue-500'>info@kanemaonline.com</a> or via 
                    pop-up notifications when using <a href="https://katswiri.com" className='text-blue-500'>katswiri.com</a>
                </p>

                <h1 className='text-white text-2xl font-bold py-2'>10. CONTACT DETAILS</h1>
                <p className='text-white text-md py-2'>
                    Static Computers Inc <br />
                    Area 47/4 <br />
                    P. O. Box 400 <br />
                    Lilongwe, 3 <br />
                    Tel: <a href="tel:+265211776931" className="text-blue-500">+265 211 776931</a> <br />
                    Tel: <a href='+265888776931' className="text-blue-500"> +265 888 776931 </a><br />
                    Tel:  <a href='+265899776931' className="text-blue-500">+265 899 776931 </a> <br />

                    Website: <a href="https://katswiri.com" target="_blank" rel="noopener noreferrer" className="text-blue-500">katswiri.com</a> <br />

                    <a href="https://staticcomputersinc.com" target="_blank" rel="noopener noreferrer" className="text-blue-500">staticcomputersinc.com</a> <br />
                  

                    Email: <a href="mailto:info@katswiri.com" target="_blank" rel="noopener noreferrer" className="text-blue-500">info@katswiri.com <br /></a>
                    <a href="mailto:info@staticcomputersinc.com" target="_blank" rel="noopener noreferrer" className="text-blue-500">info@staticcomputersinc.com <br /></a>
                </p>
                

                


            </div>

        </div>
    )
}

export default TermsBody