import React from 'react'
import Logo from '../assets/images/LOGO.png'
import { Link } from 'react-router-dom';



function Terms_Privacy_Nav() {
  return (
    <nav className="bg-custom-color">
      <div className="container mx-auto px-4 py-2 flex items-center justify-between">
        <Link to="/"><img className="w-12" src={Logo} alt="Tailwind CSS logo" /></Link>
        <button className={`text-white border border-white hover:bg-gray-900 py-2 px-3 rounded-full`}>Sign In</button>
        
      </div>
    </nav>

  )
}

export default Terms_Privacy_Nav