import React from 'react';
import phoneimg from '../assets/images/Frame.png';

function Column3() {
    return (
        <div className='container mx-auto md:mt-8 md:py-5'>
            <div className="flex flex-col-reverse md:flex-row md:items-center md:justify-center mb-8">
                <div className="md:w-3/5 text-center md:text-left mb-4 md:mb-0">
                    <img src={phoneimg} alt="Image" className="w-full pr-5 h-auto" />
                </div>
                <div className="md:w-2/5 justify-start text-center md:text-left">
                    <h1 className="text-3xl md:text-4xl font-bold mb-2 text-gray-900 md:w-[20vw]">Get The Best Talent For Your Work</h1>
                    <p className="text-sm md:text-base mb-4 text-gray-900">We Connect You to The Best Talent Around, <br /> You and Beyond</p>
                    <button className="bg-custom-color hover:bg-gray-900 text-white py-2 px-4 rounded-full ml-0 text-sm md:text-lg">Browse Talent</button>
                </div>
            </div>
        </div>
    );
}

export default Column3;
