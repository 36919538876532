import React from 'react'

export default function
  () {
  return (
    <div className='bg-custom-color mx-4 md:mx-10 my-10 rounded-3xl py-2'>
      <div className='flex flex-col md:flex-row'>
        <div className='md:w-1/2 text-white px-6 md:px-10 text-center md:text-left'>
          <h1 className="text-2xl md:text-4xl font-bold mb-2 text-white md:pt-8">Find. <br /> Experts and Pros</h1>
          <p className='text-sm md:text-lg mb-4 text-white'>Browse Experts by Reviews, Star Rating, Skills, and Price</p>
          <button className="bg-white hover:bg-blue text-custom-color py-2 px-6 md:my-4 rounded-full">Sign Up</button>
        </div>

        <div className='md:w-1/2 text-white px-6 md:px-10 text-center md:text-left'>
          <h1 className="text-2xl md:text-4xl font-bold mb-2 text-white pt-8">Earn. <br /> With Your Skills</h1>
          <p className='text-md md:text-md mb-4 text-white'>Find a Job that suits your skills and make money</p>
          <button className="bg-white hover:bg-blue text-custom-color py-2 px-6 md:my-4 rounded-full">Become Katswiri</button>
        </div>
      </div>
    </div>

  )
}
