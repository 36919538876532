import React from 'react'

 export default function 
() {
  return (
    <div className='bg-custom-color mx-2 md:mx-10 items-center justify-center mt-20 md:mt-40 rounded-2xl'>
        <h1 className="text-3xl md:text-6xl font-bold mb-2 text-white text-center py-20">Get To Know How Katswiri Works</h1>
    </div>
  )
}
