import React from 'react'
import { Link } from 'react-router-dom'
import { MdArrowForward } from 'react-icons/md';


export default function
    () {
    return (
        <div className='mx-4 md:mx-24 mt-8 flex'>
            <div className='w-4/10 pr-8 md:pr-40'>
                <nav className="vertical-nav py-8">
                    <ul>
                        <li className='py-1 font-bold'>
                            <Link to="/">Terms of Use</Link>
                        </li>
                        <li className='py-1 font-bold'>
                            <Link to="/about">Privacy</Link>
                        </li>
                        <li className='py-1 font-bold'>
                            <Link to="/services">Help</Link>
                        </li>
                        <li className='py-1 font-bold'>
                            <Link to="/services">FAQ</Link>
                        </li>
                    </ul>
                </nav>
            </div>

            <div className='w-6/10 py-8'>
                <h1 className='text-custom-color font-bold text-2xl md:text-5xl py-2 md:py-4'>Still Looking For More?</h1>
                <p className='text-custom-color text-md md:text-xl font-bold py-2 md:py-4'>Reach Out, we are here to help</p>
                <button className="bg-custom-color text-xl font-bold text-white hover:bg-gray-900 px-6 md:px-8 mx-2 rounded-full py-2 md:py-4 flex items-center">
            Contact
            <MdArrowForward className='ml-2 md:ml-8' size={20} color='white' />
        </button>
            </div>

        </div>
    )
}
