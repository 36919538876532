import React from 'react'

export default function
  () {
  return (
    <div className='flex flex-col md:flex-row mx-2 md:mx-10'>

      <div className='w-full md:w-1/2 mb-4 md:mb-0 border border-custom-color rounded-2xl md:mr-2'>
        <p className='text-xl text-custom-color p-4 md:p-8'>
          <span className='font-bold'>Katswiri</span> is your gateway to clients, talents, and service providers. Our 24/7 call centre is ready to help anytime with any problem and connect you to talented individuals ready to work on your project. Freelancers showcase their abilities by creating their profiles, expertise, and range of services. Clients post their Gigs for freelancers to bid.
        </p>
      </div>

      <div className='w-full md:w-1/2 border border-custom-color rounded-2xl'>
        <p className='text-xl text-custom-color p-4 md:p-8'>
          Disclaimer: <span className='font-bold'>Katswiri</span> requires that all users be at least 18 years of age and of legal age to use the platform. Users under the age of 18 are not permitted to use the site.
        </p>
        <a href='#' className='text-xl text-custom-color px-4 md:px-8 font-bold'>Terms of Service</a>
      </div>

    </div>


  )
}
