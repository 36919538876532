// HomePage.jsx
import React from 'react';
import Hero from '../components/Hero';
import Column1 from '../components/Column-1';
import Column2 from '../components/Column-2';
import Column3 from '../components/Column3';
import Column4 from '../components/Column4';
import Services from '../components/Services';
import DivCont from '../components/DivCont';
import AppLinks from '../components/AppLinks';
import Footer from '../components/Footer';

function HomePage() {
    return (
        <div>
            <Hero />

            <Column1 />

            <Column2 />

            <Column3 />

            <Column4 />

            <Services />

            <DivCont />

            <AppLinks />

            <Footer />


        </div>
    );
}

export default HomePage;
